<template>
  <b-container fluid>
    <info-kasus :validUser="validUser" :validReport="validReport" @refreshContent="refreshContent"></info-kasus>
    <hr/>
    <info-progress :validUser="validUser" :validReport="validReport" :validLog="validLog" @refreshContent="refreshContent"></info-progress>
  </b-container>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _interfaces from '@/templates/_interfaces';
import Multiselect from "vue-multiselect";
import { ModelSelect } from "vue-search-select";
import InfoKasus from './subcomponent/InfoKasus.vue'
import InfoProgress from './subcomponent/InfoProgress.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
    Multiselect,
    ModelSelect,
    axios,
    InfoKasus,
    InfoProgress
  },
  data() {
    return {
      Loading: false,
      validUser: false,
      validReport: false,
      validLog: false,
    };
  },
  methods: {
    init(){
      this.checkValidUser();
    },
    checkValidUser(){
      var checkrole = localStorage.getItem('role');
      var identifier = localStorage.getItem('identifier');
      axios.post("idp/penanganan/check-valid-user",{id:this.$route.params.id,role:checkrole,identifier:identifier}).then((response) => {
        this.validUser = response.data.valid;
        this.validReport = response.data.valid_report;
        this.validLog = response.data.valid_log;
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
        this.modalShow = false;
      });  
    },
    refreshContent(){
      this.init();
    }
  },
  mounted(){
    this.init();
  }
};
</script>