<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-button v-if="validApply == false && (status == 0 || status == 1 || status == 2) && Loading == false" class="right" size="sm" variant="primary" @click="ajukanDiri">Ajukan Diri Sebagai Anggota</b-button>&nbsp;
        <b-badge v-for="item in summary" :key="item.id" :variant="item.variant" size="lg" class="float-right"> {{ item.total }} {{ item.status_name }} </b-badge>&nbsp;
        <feather-icon  style="margin-left:20px; color: white" icon="RefreshCwIcon" class="cursor-pointer" @click="getDataAnggota"/>
      </b-col>
    </b-row>
    <br/>
    <b-row>
      <b-col md="12">
        <loading v-if="Loading"></loading>
        <b-table v-else :items="anggota" :fields="anggotaFields" :sortable="true" :per-page="perPageAnggota" :current-page="currentPageAnggota" show-empty>
          <template v-slot:cell(action)="row">
            <b-button v-if="validUser && row.item.status != 1" @click="actionAnggota(row,1)" variant="success" size="sm" class="custom-small-btn"><feather-icon icon="CheckIcon" class="cursor-pointer"/></b-button>&nbsp;
            <b-button v-if="validUser && row.item.status == 1" @click="actionAnggota(row,2)" variant="warning" size="sm" class="custom-small-btn"><feather-icon icon="ShuffleIcon" class="cursor-pointer"/></b-button>&nbsp;
            <b-button v-if="validUser" @click="actionAnggota(row,3)" variant="danger" size="sm" class="custom-small-btn"><feather-icon icon="TrashIcon" class="cursor-pointer"/></b-button>
          </template>
          
          <template v-slot:cell(anggota_name)="row">
            <div v-if="row.item.status == 4"><img :src="getBadgeSrc(row.item.badge)" height="25px" :class="{ 'glowing-image': row.item.grade > 20 }"/> <img :src="getColorUnit(row.item.police_type)" height="25px" /> {{ row.item.anggota_name }} <feather-icon style="color:yellow;" icon="FlagIcon" class="cursor-pointer"/></div>
            <div v-if="row.item.status == 1" style="color: yellowgreen;"><strong><img :src="getBadgeSrc(row.item.badge)" height="25px" :class="{ 'glowing-image': row.item.grade > 20 }" /> <img :src="getColorUnit(row.item.police_type)" height="25px" width="3px" /> {{ row.item.anggota_name }} <feather-icon icon="CheckIcon" class="cursor-pointer"/> </strong></div>
            <div v-if="row.item.status == 2" style="color: yellow;"><strike><img :src="getBadgeSrc(row.item.badge)" height="25px" :class="{ 'glowing-image': row.item.grade > 20 }" /> <img :src="getColorUnit(row.item.police_type)" height="25px" width="3px" /> {{ row.item.anggota_name }} <feather-icon icon="ShuffleIcon" class="cursor-pointer"/> </strike></div>
            <div v-if="row.item.status == 3" style="color: red;"><strong><strike><img :src="getBadgeSrc(row.item.badge)" height="25px" :class="{ 'glowing-image': row.item.grade > 20 }" /> <img :src="getColorUnit(row.item.police_type)" height="25px" width="3px" /> {{ row.item.anggota_name }}</strike></strong></div>
          </template>
          <template #empty>
            <div class="text-center my-3">
              Tidak ada data yang tersedia saat ini.
            </div>
          </template>
        </b-table>
        <div class="d-flex justify-content-end" v-if="!Loading">
          <b-pagination v-model="currentPageAnggota" :total-rows="anggota.length" :per-page="perPageAnggota"></b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _interfaces from '@/templates/_interfaces';
import Multiselect from "vue-multiselect";
import { ModelSelect } from "vue-search-select";


export default {
  components: {
    Loading,
    ToastificationContent,
    Multiselect,
    ModelSelect,
    axios
  },
  data() {
    return {
      Loading: false,
      status: null,
      perPageAnggota: 5, // Jumlah item per halaman
      currentPageAnggota: 1, // Halaman saat ini
      anggota: [],
      validApply: true,
      summary: [],
      anggotaFields: [
        {key:'anggota_name',label:'Name', sortable: true}, 
        {key:'status_name',label:'Status', sortable: true},
        {key:'action',label:'Action'}
      ],
    };
  },
  props: {
    validUser: {
      type: Boolean,
      default: false,
    },
    validReport: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    init(){
      this.getDataAnggota();
      this.getStatus();
    },
    getStatus(){
      axios.post("idp/penanganan/status",{id:this.$route.params.id}).then((response) => {
        this.status = response.data.data
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
      }); 
    },
    getDataAnggota(){
      this.Loading = true;
      axios.post("idp/penanganan/anggota",{id:this.$route.params.id}).then((response) => {
        this.anggota = response.data.anggota;
        this.summary = response.data.summary;

        var isStringInArray = null;
        this.validApply = this.anggota.some(item => item.identifier === localStorage.getItem('identifier'));
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.$emit('refreshAnggota');
        this.Loading = false;
      }); 
    },
    ajukanDiri(){
      this.$swal({
            title: "Siap bertanggungjawab untuk penanganan?",
            icon: "question",
            background: "black",
            showCancelButton: true,
            confirmButtonColor: "#7367f0",
            cancelButtonColor: "#ea5455",
            confirmButtonText: "Save",
          }).then((result) => {
            if (result.isConfirmed) {  

              this.Loading = true;
              setTimeout(() => {
                axios.post("idp/penanganan/add-anggota",{id:this.$route.params.id}).then((response) => {

                }).catch((error) => {
                  if (error.status === 4000) {
                    localStorage.clear();
                    this.$router.push({ path: "/login" });
                    ({
                      title: "Login Expired",
                      message: "Sesi Login Habis",
                    });
                  }
                }).finally(() => {
                  this.getDataAnggota();
                  this.LoadingAdd = false;
                  this.init();
                });
              }, 1000);

            } else {
              this.modalShow = false
            }
          });
    },
    actionAnggota(list,act){

      var swal_title = '';
      
      

          this.Loading = true;
            axios.post("idp/penanganan/approve-anggota",{id:list.item.id,id_kasus_berjalan:list.item.id_penanganan,identifier:list.item.identifier,status:act}).then((response) => {
              
            }).catch((error) => {
              if (error.status === 4000) {
                localStorage.clear();
                this.$router.push({ path: "/login" });
                ({
                  title: "Login Expired",
                  message: "Sesi Login Habis",
                });
              }
            }).finally(() => {
              this.Loading = false;
              this.init();
              this.$emit('refreshAnggota');
            });

    },
    getBadgeSrc(path) {
      if(path == 'serpa.jpg'){
        return require('@/assets/images/avatars/serpa.jpg');
      }else if(path == 'serdu.jpg'){
        return require('@/assets/images/avatars/serdu.jpg');
      }else if(path == 'serpol.jpg'){
        return require('@/assets/images/avatars/serpol.jpg');
      }else if(path == 'serka.jpg'){
        return require('@/assets/images/avatars/serka.jpg');
      }else if(path == 'pelma.jpg'){
        return require('@/assets/images/avatars/pelma.jpg');
      }else if(path == 'peldu.jpg'){
        return require('@/assets/images/avatars/peldu.jpg');
      }else if(path == 'letma.jpg'){
        return require('@/assets/images/avatars/letma.jpg');
      }else if(path == 'letdu.jpg'){
        return require('@/assets/images/avatars/letdu.jpg');
      }else if(path == 'kapten.jpg'){
        return require('@/assets/images/avatars/kapten.jpg');
      }else if(path == 'mayor.jpg'){
        return require('@/assets/images/avatars/mayor.jpg');
      }else if(path == 'letkol.jpg'){
        return require('@/assets/images/avatars/letkol.jpg');
      }else if(path == 'kolonel.jpg'){
        return require('@/assets/images/avatars/kolonel.jpg');
      }else if(path == 'serjen.jpg'){
        return require('@/assets/images/avatars/serjen.jpg');
      }else if(path == 'mayjen.jpg'){
        return require('@/assets/images/avatars/mayjen.jpg');
      }else if(path == 'letjen.jpg'){
        return require('@/assets/images/avatars/letjen.jpg');
      }else if(path == 'jendral.jpg'){
        return require('@/assets/images/avatars/jendral.jpg');
      }else if(path == 'kadop.jpg'){
        return require('@/assets/images/avatars/kadop.jpg');
      }else if(path == 'sekertaris.jpg'){
        return require('@/assets/images/avatars/sekertaris.jpg');
      }
    },
    getColorUnit(police_type) {
      if(police_type == '1'){
        return require('@/assets/images/avatars/polsip.jpg');
      }else if(police_type == '3'){
        return require('@/assets/images/avatars/polmas.jpg');
      }else if(police_type == '2'){
        return require('@/assets/images/avatars/polsus.jpg');
      }
    }
  },
  mounted(){
    this.init();
  }
}
</script>
<style scoped>
.header {
  padding: 20px;
}

.header h3 {
  margin: 0;
}

@media (min-width: 768px) {
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

h1 {
  text-align: center;
}

.custom-small-btn {
  padding: 0.25rem 0.5rem; /* Atur padding sesuai keinginan */
  font-size: 0.75rem; /* Ukuran font tombol */
  line-height: 1.25; /* Jarak baris */
}

@keyframes glowZoom {
    0% {
        transform: scale(1);
        filter: brightness(1);
    }
    50% {
        transform: scale(1.1); /* Zoom sedikit */
        filter: brightness(1.4); /* Efek pendar */
    }
    100% {
        transform: scale(1);
        filter: brightness(1);
    }
}

/* Kelas untuk gambar dengan efek glowing dan zoom */
.glowing-image {
    animation: glowZoom 1.3s infinite; /* Durasi dan infinite loop */
}

</style>