<template>
  <div class="police-page">
    <!-- Informasi Kasus -->
    <!-- <b-card class="case-info-card mb-3"> -->
      <b-row>
        <b-col md="12">
          <h3 class="mb-3 text-center">{{ namaKasus }} <br/><small></small></h3>
          <hr/>
        </b-col>
        <b-col md="6">
          <b-card style="height: 100%;">
            <b-row>
              <feather-icon  style="margin-left:20px; color:white" icon="RefreshCwIcon" class="cursor-pointer" @click="getData"/>
              <b-col md="12" class="text-center">Komando Penanganan</b-col>
            </b-row>
            <br/>
            <validation-observer ref="penanganan" tag="form" @submit.prevent="submitAdd">
              <loading v-if="LoadingAdd"></loading>
              <b-form v-else @submit.prevent="submitAdd">
                <b-row>
                  <b-col md="12">
                    <b-form-group label="Komando:" label-for="komando">
                      <validation-provider #default="{ errors }" name="komando" rules="required">
                        <multiselect :disabled="statusKasus == 3 || statusKasus == 4 || statusKasus == 5 || !validUser" v-model="formPenanganan.komando" :options="dataAnggotaAktif" :multiple="false" :close-on-select="true" :clear-on-select="true" placeholder="Pilih Penanggungjawab" label="text" track-by="value"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Negosiator 1:" label-for="negosiator1">
                      <validation-provider #default="{ errors }" name="negosiator1" rules="required">
                        <multiselect :disabled="statusKasus == 3 || statusKasus == 4 || statusKasus == 5 || !validUser" v-model="formPenanganan.negosiator1" :options="dataAnggotaAktif" :multiple="false" :close-on-select="true" :clear-on-select="true" placeholder="Pilih Negosiator" label="text" track-by="value"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Negosiator 2:" label-for="negosiator2">
                      <validation-provider #default="{ errors }" name="negosiator2" rules="">
                        <multiselect :disabled="statusKasus == 3 || statusKasus == 4 || statusKasus == 5 || !validUser" v-model="formPenanganan.negosiator2" :options="dataAnggotaAktif" :multiple="false" :close-on-select="true" :clear-on-select="true" placeholder="Pilih Negosiator" label="text" track-by="value"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Penanggungjawab Kasus :" label-for="komando">
                      <validation-provider #default="{ errors }" name="penanggungjawab" rules="required">
                        <multiselect :disabled="statusKasus == 3 || statusKasus == 4 || statusKasus == 5 || !validUser" v-model="formPenanganan.penanggungjawab" :options="dataWarga" :multiple="true" :close-on-select="true" :clear-on-select="true" placeholder="Pilih Penanggungjawab" label="text" track-by="value"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="ID Loker :" label-for="komando">
                      <validation-provider #default="{ errors }" name="penanggungjawab" rules="">
                        <b-form-input type="number" :disabled="statusKasus == 3 || statusKasus == 4 || statusKasus == 5 || !validUser" v-model="formPenanganan.loker_id" :options="dataWarga" :multiple="true" :close-on-select="true" :clear-on-select="true" placeholder="ex) 12398738722671716" style="color:black;background-color:white"/>
                        <label style="color:white;font-size:10px" @click="showtutor()"><i>-Click tutorial Cara mengisi-</i></label>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Nomor Loker :" label-for="komando">
                      <validation-provider #default="{ errors }" name="penanggungjawab" rules="">
                        <b-form-input type="number" :disabled="statusKasus == 3 || statusKasus == 4 || statusKasus == 5 || !validUser" v-model="formPenanganan.loker_no" :options="dataWarga" :multiple="true" :close-on-select="true" :clear-on-select="true" placeholder="ex) 2118" style="color:black;background-color:white"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-button v-if="validUser && (statusKasus == 0 || statusKasus == 1 || statusKasus == 2)" size="sm" variant="primary" @click="submitAdd" :disabled="statusKasus == 1 && !validUser">Update</b-button>&nbsp;
                    <b-button v-if="(validUser && (statusKasus == 2)) || (role == 1 || role == 2)" @click="updateStatus" size="sm" variant="success">Ubah Status</b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card style="height: 100%;">
            <info-anggota :validUser="validUser" :validReport="validReport" @refreshAnggota="refreshAnggota"></info-anggota>
          </b-card>
        </b-col>
      </b-row>
    <!-- </b-card> -->


    <b-modal v-model="modalStatus" title="Ubah Status" hide-footer size="sm" centered>
      <validation-observer ref="ubahstatus" tag="form" @submit.prevent="submitChangeStatus">
        <loading v-if="LoadingUpdate"/>
        <b-form v-if="!LoadingUpdate" @submit.prevent="submitChangeStatus">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Status Penanganan">
                <validation-provider #default="{ errors }" name="suspect" rules="required">
                  <multiselect @input="submitChangeStatus" v-model="status" :options="listStatus" :multiple="false" :close-on-select="true" :clear-on-select="true" placeholder="Pilih STATUS" label="text" track-by="value" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button type="submit" variant="primary" style="cursor:hand;">Submit</b-button>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal v-model="modalTutor" title="Cara Mendapatkan Thread ID" hide-footer size="md" centered>
      <div class="d-flex">
        <img :src="require('@/assets/images/avatars/TUTOR.jpg')" class="text-center"/>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _interfaces from '@/templates/_interfaces';
import Multiselect from "vue-multiselect";
import { ModelSelect } from "vue-search-select";
import InfoAnggota from './InfoAnggota.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
    Multiselect,
    ModelSelect,
    axios,
    InfoAnggota
  },
  data() {
    return {
      role: localStorage.getItem('role'),
      LoadingAdd: false,
      LoadingUpdate: false,
      statusKasus: null,
      namaKasus: null,
      modalStatus: false,
      status: null,
      perPageAnggota: 5, // Jumlah item per halaman
      currentPageAnggota: 1, // Halaman saat ini
      formPenanganan: _interfaces.penanganan,
      dataAnggotaAktif: [],
      dataWarga: [],
      dataKasus: [],
      listStatus: [],
      modalTutor: false,
    };
  },
  props: {
    validUser: {
      type: Boolean,
      default: false,
    },
    validReport: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    init(){
      this.getData();
      this.getDataAnggota();
      this.getDataUser();
      this.getDataPJ();
      this.getListStatus();
    },
    showtutor(){
      this.modalTutor = true;
    },
    getListStatus(){
      axios.get("idp/penanganan/list-status").then((response) => {
        this.listStatus = response.data.data
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
      });
    }, 
    refreshAnggota(){
      this.getDataAnggota();
    },
    getDataAnggota(){
      axios.post("idp/penanganan/anggota-aktif",{id:this.$route.params.id}).then((response) => {
        this.dataAnggotaAktif = response.data.anggota
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
      });
    },
    getDataUser(){
      axios.get("idp_dropdown/user").then((response) => {
        this.dataWarga = response.data.data
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
      });
    },
    getData() {
      this.LoadingAdd = true;
      axios.post("idp/penanganan/detail",{id:this.$route.params.id}).then((response) => {
        this.dataKasus = response.data.data;
        this.formPenanganan.komando = response.data.data.komando
        this.formPenanganan.negosiator1 = response.data.data.negosiator1
        this.formPenanganan.negosiator2 = response.data.data.negosiator2
        this.formPenanganan.penanggungjawab = response.data.data.penanggungjawab
        this.formPenanganan.loker_id = response.data.data.loker_id
        this.formPenanganan.loker_no = response.data.data.loker_no
        this.statusKasus = response.data.data.status
        this.namaKasus = response.data.data.name
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.LoadingAdd = false;
      });
    },
    getDataPJ() {
      axios.get("idp_dropdown/user").then((response) => {
        this.dataPJ = response.data.data
        
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
      });
    },
    addImageModal(){
      this.modalImage = true;
    },
    submitAdd() {
      this.$refs.penanganan.validate().then((success) => {
        if(success){ 
          this.$swal({
            title: "Submit Form Penanganan?",
            icon: "question",
            background: "black",
            showCancelButton: true,
            confirmButtonColor: "#7367f0",
            cancelButtonColor: "#ea5455",
            confirmButtonText: "Save",
          }).then((result) => {
            if (result.isConfirmed) {  

              this.LoadingAdd = true;
              setTimeout(() => {
                this.formPenanganan.id = this.$route.params.id;
                axios.post("idp/penanganan/submit",this.formPenanganan).then((response) => {
                  if(response.data.status == 2000){
                    this.$swal({
                      title: "Success",
                      icon: "success",
                      background: "black",
                      confirmButtonColor: "#7367f0",
                      confirmButtonText: "Close",
                    });
                  }
                }).catch((error) => {
                  if (error.status === 4000) {
                    localStorage.clear();
                    this.$router.push({ path: "/login" });
                    ({
                      title: "Login Expired",
                      message: "Sesi Login Habis",
                    });
                  }
                }).finally(() => {
                  this.LoadingAdd = false;
                  this.modalImage = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Submit Success",
                      icon: "EditIcon",
                      variant: "success",
                      text: "Kasus Ditambahkan",
                    },
                  });
                  this.init();
                  // window.location.reload();
                });
              }, 1000);

            } else {
              this.modalShow = false
            }
          });
        }
      });
    },
    viewHD(image){
      this.selectedImageHD = image;
      this.modalHD = true;
    },
    showAddPJModal(){
      // alert("a");
      this.modalPJ = true;
    },
    showChangeStatus(){
      this.modalChangeStatus = true;
    },
    updateStatus(){
      this.modalStatus = true;
    },
    submitChangeStatus(){
      this.$refs.ubahstatus.validate().then((success) => {
        if(success){
          this.$swal({
            title: "Ubah Status?",
            icon: "question",
            background: "black",
            showCancelButton: true,
            confirmButtonColor: "#7367f0",
            cancelButtonColor: "#ea5455",
            confirmButtonText: "Save",
          }).then((result) => {
            if (result.isConfirmed) {  
    
              this.LoadingUpdate = true;
              setTimeout(() => {
                axios.post("idp/penanganan/close",{id:this.$route.params.id,status:this.status.value}).then((response) => {
                  
                }).catch((error) => {
                  if (error.status === 4000) {
                    localStorage.clear();
                    this.$router.push({ path: "/login" });
                    ({
                      title: "Login Expired",
                      message: "Sesi Login Habis",
                    });
                  }
                }).finally(() => {
                  this.LoadingUpdate = false;
                  this.modalStatus = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Success",
                      icon: "EditIcon",
                      variant: "success",
                      text: "Kasus Selesai",
                    },
                  });
                  window.location.reload();
                  this.init();
                });
              }, 1000);
    
            } else {
              this.modalStatus = false
            }
          });
        }
      });
    }
  },
  mounted(){
    this.init();
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>